<template>
  <div>
    <div>
      <div class="headline p-md-5 d-flex align-items-end" :style="'background: linear-gradient(0.52deg, rgba(33, 37, 43, 0.53) 14.72%, rgba(33, 37, 43, 0) 168.66%), url(/news/custom-banner.jpg) center/cover'">
        <div class="text pb-4 pb-md-3 pt-5 px-3 px-md-5">
          <div class="category">Цікаво знати</div>
          <h1 class="m-0">Шлях до будинку вашої мрії не повинен бути складним</h1>
        </div>
      </div>
      <div class="news m-auto py-4 py-md-5 custom-article">
        <div class="news-wrapper detail">
          <div class="article-container">
            <p class="mb-5 strong">Придбайте для себе новий дім у районі Бори, який пропонує розвинену соціально-побутову інфраструктуру, безліч дитячих та спортивних майданчиків, греблю прямо під вашим будинком, а також дошкільний навчальний заклад і магазини всього за кілька хвилин від вашого дому.</p>
            <h6>Ви можете вибрати одну з квартир із серій: </h6>
            <p class="mb-2"><a href="/byvanie/novy-dvor">Bory Nový Dvor</a></p>
            <p class="mb-2"><a href="/byvanie/bory3">Bory Promenáda</a></p>
            <p class="mb-2"><a href="/byvanie/na-hradzi">Bory Na Hrádzi</a></p>
            <p>У продажу ви знайдете широкий вибір від одно- до чотирикімнатних квартир.</p>

            <h2>Як профінансувати придбання житла в проєкті Bory Bývanie?</h2>
            <p>Ми познайомимо вас з можливостями та процесом фінансування вашої нової нерухомості в наших проєктах.</p>
            <h3>Чи може громадянин України взяти іпотечний кредит у Словаччині?</h3>
            <p>Можливість фінансування нерухомості через іпотечний кредит для громадян України існує при виконанні стандартних умов банків, аналогічних умовам, які діють для громадян Словаччини. Головною умовою є підтвердження постійного доходу з місця роботи або доходу від підприємницької діяльності. Другою умовою є документально підтверджене тимчасове або постійне проживання в Словаччині.</p>

            <h3 class="toggle" :class="{visible: isVisible}" @click="toggleTimeline">Процес фінансування за 8 чітких кроків <span><img src="../assets/images/close-sidebar-icon.svg"></span></h3>
            <div class="timeline" :class="{visible: isVisible}">
                <div class="step step-1">
                    <div class="num">1</div>
                    <div class="desc">
                        <p class="strong">Ви обираєте квартиру/квартири, які вас цікавлять.</p>
                    </div>
                </div>
                <div class="step step-2">
                    <div class="num">2</div>
                    <div class="desc">
                        <p class="strong">Ви звертаєтесь до нашого продавця, який проконсультує вас щодо вибору квартири, а після підготовки договору з'єднає вас з фахівцем з іпотечного кредитування.</p>
                    </div>
                </div>
                <div class="step step-3">
                    <div class="num">3</div>
                    <div class="desc">
                        <p class="strong">Наш іпотечний спеціаліст організує індивідуальну зустріч з вами та підготує для вас найвигідніші пропозиції стосовно фінансування протягом 24 годин після зустрічі.</p>
                    </div>
                </div>
                <div class="step step-4">
                    <div class="num">4</div>
                    <div class="desc">
                        <p class="strong">Він підготує заявку на отримання іпотечного кредиту в обраному вами банку/банках і здійснюватиме від вашого імені всю необхідну комунікацію та підготує документацію для банку.</p>
                    </div>
                </div>
                <div class="step step-5">
                    <div class="num">5</div>
                    <div class="desc">
                        <p class="strong">Ви сплачуєте 10% від вартості покупки.</p>
                        <p class="light">Ви можете використати для цього власні кошти або, в деяких ситуаціях, взяти кредит на перший внесок без застави квартири. Це можливо лише в обраних банках. У такому випадку ви здійснюєте щомісячний платіж лише за використану частину кредиту. Наш фахівець з іпотечного кредитування надасть вам більш детальну інформацію.</p>
                    </div>
                </div>
                <div class="step step-6">
                    <div class="num">6</div>
                    <div class="desc">
                        <p class="strong">Після схвалення кредиту ви заплатите решту 90% від вартості квартири.</p>
                        <p class="light">У більшості випадків ця частина фінансується за рахунок кредиту. Якщо ви вже взяли кредит на перші 10% від вартості покупки, решту 90% ви сплачуєте за рахунок кредиту.
                        </p>
                        <p class="light">В обох випадках наш іпотечний спеціаліст перевірить зміст іпотечного договору та підготує інші необхідні документи (майбутній договір купівлі-продажу, страхування предмету застави, копію страхового договору, рахунок-фактуру...). З цього моменту ви будете здійснювати повний щомісячний платіж відповідно до затвердженого іпотечного договору.</p>
                    </div>
                </div>
                <div class="step step-7">
                    <div class="num">7</div>
                    <div class="desc">
                        <p class="strong">Після сплати 100% від вартості покупки ви підписуєте договір купівлі-продажу, який ми подаємо до земельного кадастру разом із заявою про реєстрацію права власності на нерухомість. Якщо ви придбали квартиру за власні кошти, у вас є можливість відшкодувати їх за допомогою іпотечного кредиту.</p>
                    </div>
                </div>
                <div class="step step-8">
                    <div class="num">8</div>
                    <div class="desc">
                        <p class="strong">Ви отримаєте ключі від свого житла.</p>
                    </div>
                </div>
            </div>
        </div>

        <section class="temp-block px-3 px-md-0 py-3">
          <div class="d-flex flex-wrap px-0 pl-md-5 pr-md-3 justify-content-between">
            <div class="col-12 col-lg-4 mb-3 mb-lg-0 px-0 py-lg-5">
              <h2 class="mb-5">Живіть зі ставкою, зниженою на 3 %</h2>
              <p class="mb-5">Профінансуйте своє житло за допомогою іпотечного кредиту зі зниженою відсотковою ставкою у 3% річних на два роки.</p>
              <b-button to="/znizeny-urok" squared variant="primary">{{ $t('Дізнатися більше') }}</b-button>
            </div>
            <div class="col-12 col-lg-7 px-0">
              <div class="img"></div>
            </div>
          </div>
        </section>

        <section class="procare-program py-5 px-3" id="finance-box">
          <div class="container">
            <p class="title text-center mt-md-4">Переваги фінансування зі зниженою відсотковою ставкою Бори</p>
            <div class="row mb-4">
              <div class="col-12 col-md-3">
                <div class="box">
                  <div class="icon">
                  <img src="../assets/images/znizeny-urok/ikona1-urok.svg" alt="">
                </div>
                  <p>Знижена відсоткова ставка 3% річних застосовується до будь-якої затвердженої суми кредиту на житло</p>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="box">
                  <div class="icon">
                  <img src="../assets/images/znizeny-urok/ikona2-zmluva.svg" alt="">
                </div>
                  <p>Знижена відсоткова ставка фіксується договірним шляхом, безпосередньо в кредитному договорі</p>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="box">
                  <div class="icon">
                  <img src="../assets/images/znizeny-urok/ikona3-kalendar.svg" alt="">
                </div>
                  <p>Вам гарантовано нижчу суму погашення протягом перших 2-х років іпотечного кредиту з 2-річною фіксованою відсотковою ставкою</p>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="box">
                  <div class="icon">
                  <img src="../assets/images/znizeny-urok/tb_logo_biele.png" alt="">
                </div>
                  <p>Діє для заявок на іпотечний кредит, поданих у будь-якому відділенні Tatra bank або через контакт-центр DIALOG Live</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="section-3 top p-3 pt-5">
          <div>
            <div class="container mb-10">
              <div class="row px-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-5 col-12 px-md-5 py-3">
                  <h2>Фінансування без <br>складних процесів</h2>
                </div>
                <div class="col-12 col-md-5 pr-md-5">
                  <div class="box">
                    <p>Знижена відсоткова ставка Бори у розмірі 3% річних буде зафіксована безпосередньо у кредитному договорі.</p>
                    <p>Таким чином, Ви сплачуватимете меншу суму щомісячного платежу, без того, щоб забудовник згодом компенсував різницю в ціні у вигляді одноразової або щомісячної субсидії.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="section-3 p-3">
          <div>
            <div class="row">
              <div class="col-md-7 mb-4 mb-md-0">
                <img src="../assets/images/znizeny-urok/img-1.jpg">
              </div>
              <div class="col-12 col-md-5 px-md-4 py-3">
                <h2>Хто може скористатись можливістю отримати знижений відсоток Бори?</h2>
                <p>Зниженою відсотковою ставкою у розмірі 3% річних може скористатися кожен, хто зацікавлений у купівлі квартири в Бори та подасть заявку на іпотечний кредит у Tatra bankа.</p>
                <p class="mb-10">Ви можете подати заявку на отримання іпотечного кредиту самостійно або через нашого іпотечного консультанта.</p>
              </div>
            </div>
          </div>
        </section>

        <section class="loan-example py-5">
          <div class="container">
            <h2 class="px-4 text-center">Типовий приклад щомісячного платежу по іпотечному кредиту зі зниженими відсотковими ставками Бори</h2>
            <div class="row mb-5">
              <div class="col-12 col-md-4 px-4 mb-5 mb-md-0">
                <div class="box p-4">
                  <h3 class="mb-4 text-center">Погашення з комерційними відсотками</h3>
                  <div class="d-flex item">
                    <div class="left">Сума іпотеки</div>
                    <div class="right">240 000 €</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Період погашення</div>
                    <div class="right">30 років</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Період фіксації</div>
                    <div class="right">2 роки</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Відсоткова ставка за іпотечним договором</div>
                    <div class="right">4,69 % p. a.</div>
                  </div>
                  <div class="d-flex item mb-5">
                    <div class="left">Відсоткова ставка для розрахунку суми погашення</div>
                    <div class="right">4,69 % p. a.</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Щомісячний платіж</div>
                    <div class="right">1 243,29 €</div>
                  </div>
                  <div class="d-flex item mb-5">
                    <div class="left">Сума, що підлягає виплаті в кінці 2 року</div>
                    <div class="right">232&nbsp;334,08&nbsp;€</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Заощаджена різниця на погашенні</div>
                    <div class="right">-</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Заощаджена різниця на основній сумі боргу</div>
                    <div class="right">-</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Всього ви заощадите</div>
                    <div class="right">-</div>
                  </div>
                </div>
                
              </div>

              <div class="col-12 col-md-4 px-4 mb-5 mb-md-0">
                <div class="box p-4">
                  <h3 class="mb-4 text-center">Розстрочка за звичайною субсидованою іпотекою</h3>
                  <div class="d-flex item">
                    <div class="left">Сума іпотеки</div>
                    <div class="right">240 000 €</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Період погашення</div>
                    <div class="right">30 років</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Період фіксації</div>
                    <div class="right">2 роки</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Відсоткова ставка за іпотечним договором</div>
                    <div class="right">4,69 % p. a.</div>
                  </div>
                  <div class="d-flex item mb-5">
                    <div class="left">Відсоткова ставка для розрахунку суми погашення</div>
                    <div class="right">1,69 % p. a.</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Щомісячний платіж</div>
                    <div class="right">850,35 €</div>
                  </div>
                  <div class="d-flex item mb-5">
                    <div class="left">Сума, що підлягає виплаті в кінці 2 року</div>
                    <div class="right">232&nbsp;334,08&nbsp;€</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Заощаджена різниця на погашенні</div>
                    <div class="right">9 430,56 €</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Заощаджена різниця на основній сумі боргу</div>
                    <div class="right">-</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Всього ви заощадите</div>
                    <div class="right">9 430,56 €</div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-4 px-4">
                <div class="box p-4 bory">
                  <h3 class="text-blue mb-4 text-center">Розстрочка зі зниженими процентними ставками</h3>
                  <div class="d-flex item">
                    <div class="left">Сума іпотеки</div>
                    <div class="right">240 000 €</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Період погашення</div>
                    <div class="right">30 років</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Період фіксації</div>
                    <div class="right">2 роки</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Відсоткова ставка за іпотечним договором</div>
                    <div class="right">1,69 % p. a.</div>
                  </div>
                  <div class="d-flex item mb-5">
                    <div class="left">Відсоткова ставка для розрахунку суми погашення</div>
                    <div class="right">1,69 % p. a.</div>
                  </div>
                  <div class="d-flex item border-blue">
                    <div class="left">Щомісячний платіж</div>
                    <div class="right font-weight-bold">850,35 €</div>
                  </div>
                  <div class="d-flex item mb-5">
                    <div class="left">Сума, що підлягає виплаті в кінці 2 року</div>
                    <div class="right">227&nbsp;502,39&nbsp;€</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Заощаджена різниця на погашенні</div>
                    <div class="right">9 430,56 €</div>
                  </div>
                  <div class="d-flex item">
                    <div class="left">Заощаджена різниця на основній сумі боргу</div>
                    <div class="right">4 831,69 €</div>
                  </div>
                  <div class="d-flex item border-blue">
                    <div class="left">Всього ви заощадите</div>
                    <div class="right font-weight-bold">14 262,25 €</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="note">
              <p class="mb-4">* Ми розрахували для Вас типовий приклад з пропозиції 3-кімнатної квартири, ціна якої з паркомісцем та підвалом становить 300 000 євро. <br>Таким чином, сума іпотечного кредиту при фінансуванні 80% вартості нерухомості та 30-річному терміні погашення становитиме 240 000 євро.</p>
              <p class="font-size-default mb-4">З повними умовами акції Ви можете ознайомитися в <a href="../../docs/Podmienky_akcie_Znizeny_urok.pdf" target="_blank" class="w-fw-700">Умовах акції</a></p>
              <p class="font-size-default">З пропозицією кредитів на придбання житла від Tatra banka, в тому числі з репрезентативним прикладом, можна ознайомитися <a href="https://www.tatrabanka.sk/sk/personal/uvery/hypoteka/" target="_blank">на сайті</a>.</p>
            </div>
          </div>
        </section>

        <div class="full-banner">
            <div class="image"><img src="/news/custom-full-banner.jpg"></div>
            <div class="text">
                <h2>Перегляньте актуальну пропозицію квартир</h2><a href="/byty" class="btn">Переглянути пропозицію</a>
            </div>
        </div>
        </div>
      </div>
    </div>
    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";
import news from "@/store/news.json"

export default {
  name: "UaFinancovanie",
  components: {ContactForm},
  data() {
    return {
      articleId: this.$route.params.id,
      news: news,
      isVisible: false
    }
  },
  methods: {
    toggleTimeline() {
      this.isVisible = !this.isVisible
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  computed: {
    article() {
      return news.items.find(
        article => article.slug === this.articleId
      )
    }
  },
  watch:{
    $route (to){
      this.articleId = to.params.id
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.temp-block
  background: var(--dark)

  h2
    margin-bottom: 0
    @media (max-width: $md)
      margin-bottom: 30px
  p
    @media (max-width: $md)
      br
        display: none
  .img
    width: 100%
    height: 100%
    background: url("../assets/images/nahradzi.jpg") no-repeat center
    background-size: cover
    @media (max-width: $md)
      min-height: 250px
      margin-bottom: 20px

.section-3
  background: var(--textDark)
  color: #fff
  text-align: left

  &.top
    background: var(--textBright)

  .mb-10
    margin-bottom: 60px

    @media (max-width: $md)
      margin-bottom: 0

  .box
    padding: 40px 30px
    @media (max-width: $md)
      padding: 0
  .col-p0
    @media (max-width: $md)
      padding: 0
  .bg-img
    background: url("../assets/images/znizeny-urok/img-1.jpg") center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover
  h2
    font-size: 55px
    line-height: 60px
    font-weight: 300
    margin-bottom: 30px
    letter-spacing: -3px
    margin-left: -4px
    @media (max-width: $md)
      font-size: 30px
      line-height: 30px
      letter-spacing: -2px
  p
    font-weight: 500
    font-size: 18px
    max-width: 430px
  .bg-col
    height: 800px
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/procare/call-centrum-bg.jpg") center/cover no-repeat
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/procare/SMS_1.jpg") center/cover no-repeat
    @media (max-width: $md)
      background-position: top
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/procare/klientska-zona.jpg") center/cover no-repeat
  .col-md-4
    padding-left: 8px
    padding-right: 8px

  .services
    p
      font-size: 1rem
      font-weight: 400
    .icons
      .icon
        background: rgba(255, 255, 255, 0.05)
        width: 48px
        height: 48px
        display: flex
        align-items: center
        justify-content: center

        @media (max-width: $md)
          flex: none

.procare-program
  background: var(--dark)
  .title
    font-size: 30px
    font-weight: 500
    letter-spacing: -0.24px
    margin-bottom: 50px
    line-height: 28px

    @media (max-width: $md)
      padding-left: 0
      font-size: 20px
  .box
    border: 1px solid rgba(255, 255, 255, 0.20)
    padding: 56px 40px 70px
    text-align: center
    position: relative
    min-height: 462px

    @media (max-width: $md)
      padding: 20px 20px 40px
      margin-bottom: 15px
      min-height: auto

    .link
      position: absolute
      right: 15px
      bottom: 15px
      font-size: 14px

    .icon
      height: 100px
      display: flex
      align-items: center
      justify-content: center
      margin-bottom: 40px

      img
        max-height: 100%
        height: 100%

    p
      font-size: 18px
      font-weight: 500
      margin-bottom: 40px

    .text-blue
      color: var(--blue)
      font-weight: 700
      font-size: 20px
      line-height: 24px
      margin-bottom: 0

  .notes
    padding-left: 30px
    
    @media (max-width: $md)
      padding-left: 0
    p
      margin-bottom: 0
      color: rgba(255, 255, 255, 0.50)
      font-size: 14px

.loan-example
  background: var(--darkBlue)

  .box
    border: 1px solid rgba(255, 255, 255, .2)

    &.bory
      border: none
      background: var(--textBright)

  h2
    font-size: 55px
    line-height: 60px
    font-weight: 500
    margin-bottom: 100px
    letter-spacing: -3px
    margin-left: -4px
    
    @media (max-width: $md)
      font-size: 30px
      line-height: 30px
      letter-spacing: -2px
      margin-bottom: 40px

  .item
    border-bottom: 1px solid #ccc
    display: flex
    justify-content: space-between
    align-items: center
    padding: 8px
    font-size: 14px

    &.border-blue
      background: var(--blue)

    @media (max-width: $md)
      font-size: 14px

    .left
      max-width: 60%

    .right
      font-size: 18px
      font-weight: 700

      @media (max-width: $md)
        font-size: 18px

  .note
    p
      font-size: 14px
      text-align: center
      &.font-size-default
        font-size: 16px

.full-banner
  margin-bottom: 0 !important
.news-wrapper.detail h2
  font-size: 40px
  line-height: 44px
  font-weight: bold
h1
  font-size: 64px
  line-height: 60px
  letter-spacing: -3px
  font-weight: 900
  max-width: 980px
  @media (max-width: $md)
    max-width: 100%
    font-size: 44px
    line-height: 44px
    letter-spacing: -2px
.category
  font-size: 28px
  line-height: 32px
  letter-spacing: -0.5px
  margin-bottom: 30px
  @media (max-width: $md)
    font-size: 18px
    line-height: 22px
    margin-bottom: 15px
.headline
  height: 550px
  background-size: cover
  background-position: center
  @media (max-width: $md)
    height: 70vh
.news
  max-width: 680px
  color: #fff
  background: var(--textDark)
  @media (max-width: $md)
    max-width: 100%
    padding: 0 16px
  p, h2
    color: #fff !important
  h2
    font-size: 28px
    line-height: 32px
    letter-spacing: -0.5px
    margin-bottom: 25px
    font-weight: 400
  p
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 18px
      line-height: 22px
      font-weight: 400
  .figure
    margin: 50px 0
  .figure-caption
    color: var(--grey)
    font-size: 15px
    line-height: 23px
    margin-top: 5px

  .toggle
    cursor: pointer
    display: flex

    &:hover
      color: var(--primary)

    &.visible
      span
        transform: rotate(180deg)

    span
      display: inline-block
      width: 40px
      height: 40px
      background: var(--dark)
      display: flex
      align-items: center
      justify-content: center
      border-radius: 50%
      margin-left: 15px
      transform: rotate(90deg)
      flex: none

  .timeline
    display: none
    &.visible
      display: block
</style>